<template>
  <div class="auth-page">
    <!-- Navigation -->
    <nav class="bg-white shadow-sm fixed w-full z-50 top-0">
        <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div class="flex justify-between h-16">
                <div class="flex items-center">
                  <img
                    src="/xie-trans.png"
                    width="75"
                    max-width="75"
                    class="d-block"
                  />
                </div>
                <div class="hidden md:flex items-center space-x-8">
                  <router-link to="/" class="text-gray-700 hover:text-primary">Home</router-link>
                  <a href="/#track-package" class="text-gray-700 hover:text-primary">Track Package</a>
                  <a href="/#loginto" class="btn-primary white--text px-6 py-2 rounded-full">Get Started</a>
                  <!-- <a href="#" class="text-gray-700 hover:text-primary">About</a> -->
                </div>
            </div>
        </div>
    </nav>

    <!-- Hero Section -->
    <section class="pt-32 pb-32 bg-white">
        <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div class="grid grid-cols-1 md:grid-cols-2 gap-12 items-center">
                <div>
                    <h1 class="text-4xl md:text-5xl font-bold text-gray-900 mb-6">Fast & Automated Shipping Solutions</h1>
                    <p class="text-lg text-gray-600 mb-2">Say goodbye to the hassle of tracking orders, managing product data, and creating invoices.
                    </p>
                    <p class="text-lg text-gray-600 mb-8">Our system automates every step—from order processing to invoice generation—integrating seamlessly accurate delivery every time.</p>
                    <div class="flex space-x-4">
                      <a href="/#loginto" class="btn-primary text-white white--text px-8 py-3 rounded-full">Get Started</a>
                      <a href="/#track-package" class="text-primary px-4 py-3 rounded-full hover:bg-gray-50">Track Package</a>
                    </div>
                </div>
                <div class="flex justify-center">
                    <img src="/banner.png" alt="Delivery Service Illustration" style="width: 300px;">
                </div>
            </div>
        </div>
    </section>

    <!-- Features Section -->
    <section class="pt-20 pb-10 bg-gray-50">
      <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <h2 class="text-3xl font-bold text-center mb-12">Cek Ongkir</h2>
        <v-row class="mb-4">
          <v-col cols="12" class="col-sm-6">
            <v-autocomplete
              v-model.number="origin"
              :search-input.sync="shippingOriginQuery"
              :items="shippingOriginData"
              item-value="kecamatan_id"
              item-text="fullname"
              outlined
              hide-details
              clearable
              label="- Asal Pengiriman -"
              autocomplete="disabled"
            />
          </v-col>
          <v-col cols="12" class="col-sm-6">
            <v-autocomplete
              v-model.number="destination"
              :search-input.sync="shippingDestinationQuery"
              :items="shippingDestinationData"
              item-value="kecamatan_id"
              item-text="fullname"
              outlined
              hide-details
              clearable
              label="- Tujuan Pengiriman -"
              autocomplete="disabled"
            />
          </v-col>
          <v-col>
            <v-row justify="center">
              <v-col v-for="(i, iK) in OngkirData" :key="'ongir-' + iK" cols="12" class="col-sm-4">
                <v-card class="pa-4 font-weight-bold text-center">
                  {{ i.price_detail }}
                  <div>
                    Min. Weight: {{ (parseFloat(i.weight_minimum || 0) / 1000).toFixed(1) }}KG
                  </div>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
          <v-col id="track-package" cols="12" class="pt-10">
            <div class="grey lighten-2 lighten-4 rounded-md py-10 px-0 px-md-4">
              <div class="text-h5 font-weight-bold text-black text-center">
                Track Your Package
              </div>
              <div class="fill-width text-center pt-4 px-8">
                <input v-model="track.ttk" type="text" placeholder="XTI Airwaybill" class="xti-awb inline-block text-h6 px-4 py-2 rounded-pill orange darken-2 text-white text-center fill-width" style="max-width: 420px;" @keyup="TRACK_TTK()">
              </div>
              <div class="px-4 pt-4 text-center mx-auto fill-width" style="max-width:420px;">
                <div v-if="track.loading" class="text-caption">
                  Loading
                </div>
                <div v-else-if="track.data?.detail && track.data?.data">
                  <v-card class="text-left rounded-xl px-2">
                    <v-card-text class="pr-2 pl-12" style="max-height: 90vh;">
                      <div v-for="(dp, iDp) in track.data.data" :key="'route-track-' + iDp" class="p-relative">
                        <v-card v-if="(dp.recipient_imgs && dp.recipient_imgs !== '[]') || (dp.manifest_arrival && parseInt(dp.status_plan) !== 3)" :class="[iDp === 0 && !dp.courier_detail ? 'px-5' : 'px-0']" flat :dark="(iDp === 0 && !dp.courier_detail) ? true : false" class="d-flex flex-column justify-center fill-width rounded-lg p-relative" style="min-height: 82px;">
                          {{ $localDT(dp.manifest_arrival, 'daydatetime') }}
                          <div v-if="dp.recipient_imgs && parseInt(dp.status)">
                            DELIVERED TO <span class="text-uppercase">{{ dp.recipient_relation || '-' }} ({{ dp.recipient_name || '#' }})</span>
                            <div class="d-flex align-center">
                              <v-icon class="mr-1" small>
                                mdi-note-multiple-outline
                              </v-icon>
                              {{ dp.recipient_notes || '#' }}
                            </div>
                            <div v-if="dp.recipient_imgs" class="d-flex fill-width flex-wrap">
                              <img
                                v-for="(img, iimg) in $StrToArray(dp.recipient_imgs)"
                                :key="'img-delivery-' + iimg"
                                :src="img"
                                style="height: 80px; width: 80px;"
                                class="ma-1"
                                @click.prevent="OpenBrowser(img)"
                              >
                            </div>
                            <div class="py-1">
                              <span>Sign :</span>
                              <img
                                v-if="dp.recipient_sign"
                                :src="dp.recipient_sign"
                                style="height: 80px; width: 80px;"
                                class="ma-1 d-block"
                                @click.prevent="OpenBrowser(dp.recipient_sign)"
                              >
                            </div>
                          </div>
                          <div v-else-if="!dp.courier_detail" class="text-uppercase">
                            {{ dp.store_destination_city_name || dp.store_destination_name ? ((dp.store_destination_city_name || dp.store_destination_name) + ', ') : '' }} MANIFEST IN
                            <!-- {{ dp.store_destination_name }} MANIFEST IN, {{ dp.store_destination_name }}{{ (dp.vendor_code ? (' (' + (dp.vendor_code || '-') + ')') : '') }} -->
                          </div>
                          <span v-if="iDp !== 0" style="display:block;position: absolute;top:-24%;left:-30px;height:50%;width:0px;border-left:dashed 2px #4CAF50;z-index:1;" />
                          <span :class="[(iDp !== 0 || dp.courier_detail) ? 'green' : 'primary']" style="position: absolute;top:calc(50% - 15px);left:-43px;width: 30px;height: 30px;z-index:2;" class="d-flex align-center justify-center rounded-pill">
                            <v-icon color="white" small>
                              {{ iDp !== 0 || dp.courier_detail ? 'mdi-check' : 'mdi-map-marker' }}
                            </v-icon>
                          </span>
                        </v-card>
                        <v-card v-if="dp.manifest_departure" flat class="d-flex flex-column justify-center fill-width rounded-lg px-0 p-relative" style="min-height: 82px;">
                          {{ $localDT(dp.manifest_departure, 'daydatetime') }}
                          <div v-if="dp.courier_detail">
                            PROCESS DELIVERY WITH COURIER
                            <div class="caption">
                              {{ dp.courier_detail || '-' }}
                            </div>
                          </div>
                          <div v-else class="text-uppercase">
                            {{ dp.store_origin_city_name ? (dp.store_origin_city_name + ', ') : '' }} MANIFEST OUT
                            <!-- {{ dp.store_origin_code }} {{ dp.courier_detail ? 'ON DELIVERY WITH COURIER' : 'MANIFEST OUT' }}, {{ dp.store_origin_name }}{{ (dp.vendor_code ? (' (' + (dp.vendor_code || '-') + ')') : '') }} -->
                          </div>
                          <span v-if="dp.manifest_arrival && parseInt(dp.status_plan) !== 3" style="display:block;position: absolute;top:-24%;left:-30px;height:50%;width:0px;border-left:dashed 2px #4CAF50;z-index:1;" />
                          <span :class="[!dp.manifest_arrival ? 'primary' : 'green']" style="position: absolute;top:calc(50% - 15px);left:-43px;width: 30px;height: 30px;z-index:2;" class="d-flex align-center justify-center rounded-pill">
                            <v-icon color="white" small>
                              {{ !dp.manifest_arrival ? 'mdi-map-marker' : 'mdi-check' }}
                            </v-icon>
                          </span>
                          <!-- <span style="display:block;position: absolute;bottom:-7px;left:-30px;height:50%;width:0px;border-left:dashed 2px #4CAF50;z-index:1;" /> -->
                        </v-card>
                        <v-card flat class="d-flex flex-column justify-center fill-width rounded-lg px-0 p-relative" style="min-height: 82px;">
                          {{ $localDT(dp.created, 'daydatetime') }}
                          <div class="text-uppercase">
                            <div v-if="dp.notes && ((dp.notes || 'X').split('[CANCELED]').length >= 2)" class="d-flex align-center red--text caption text-uppercase">
                              <v-icon class="mr-2" small color="red">
                                mdi-arrow-u-left-bottom
                              </v-icon>
                              {{ dp.notes || '-' }}
                            </div>
                            {{ dp.store_origin_city_name ? (dp.store_origin_city_name + ', ') : '' }} {{ dp.courier_detail ? 'DISPATCH COURIER' : 'MANIFESTED' }}
                            <!-- {{ dp.store_origin_code }} {{ dp.courier_detail ? 'DISPATCH COURIER' : 'MANIFESTED' }}, {{ dp.store_origin_name }} -->
                          </div>
                          <span v-if="dp.manifest_departure" style="display:block;position: absolute;top:-24%;left:-30px;height:50%;width:0px;border-left:dashed 2px #4CAF50 ;z-index:1;" />
                          <span :class="[!dp.manifest_departure ? 'primary' : 'green']" style="position: absolute;top:calc(50% - 15px);left:-43px;width: 30px;height: 30px;z-index:2;" class="d-flex align-center justify-center rounded-pill">
                            <v-icon color="white" small>
                              {{ !dp.manifest_departure ? 'mdi-map-marker' : 'mdi-check' }}
                            </v-icon>
                          </span>
                          <!-- <span style="display:block;position: absolute;bottom:-7px;left:-30px;height:50%;width:0px;border-left:dashed 2px #4CAF50 ;z-index:1;" /> -->
                        </v-card>
                      </div>
                      <div>
                        <v-card v-if="parseInt(track.data.detail.pickup_handling)" flat class="d-flex flex-column justify-center fill-width rounded-lg px-0 p-relative" style="min-height: 82px;">
                          <div v-if="parseInt(track.data.detail.pickup_handling_admin)" class="d-flex align-start justify-center flex-column mb-4">
                            <div class="text-uppercase">
                              Received by {{ track.data.detail.shipping_origin_city_name }} {{ track.data.detail.shipping_origin_city_type }}
                            </div>
                            <span style="position: absolute;top:calc(50% - 15px);left:-43px;width: 30px;height: 30px;z-index:2;" class="d-flex align-center justify-center green rounded-pill">
                              <v-icon color="white" small>
                                mdi-truck-fast
                              </v-icon>
                            </span>
                          </div>
                          <div v-if="parseInt(track.data.detail.pickup_handling_status)" class="d-flex align-start justify-center flex-column mb-4">
                            <div class="text-uppercase">
                              Picked-Up by {{ track.data.detail.pickup_detail }}
                            </div>
                            <span style="position: absolute;top:calc(50% - 15px);left:-43px;width: 30px;height: 30px;z-index:2;" class="d-flex align-center justify-center green rounded-pill">
                              <v-icon color="white" small>
                                mdi-truck-fast
                              </v-icon>
                            </span>
                          </div>
                          <div class="d-flex align-start justify-center flex-column mb-4">
                            <div class="text-uppercase">
                              Prepare for Pickup
                            </div>
                            <span style="position: absolute;top:calc(50% - 15px);left:-43px;width: 30px;height: 30px;z-index:2;" class="d-flex align-center justify-center green rounded-pill">
                              <v-icon color="white" small>
                                mdi-truck-fast
                              </v-icon>
                            </span>
                          </div>
                          <div class="d-flex align-start justify-center flex-column">
                            <div class="text-uppercase">
                              Order Request #{{ track.data.detail.id_refference || '-' }}, {{ track.data.detail.shipping_name }}
                            </div>
                            <span style="position: absolute;top:calc(50% - 15px);left:-43px;width: 30px;height: 30px;z-index:2;" class="d-flex align-center justify-center green rounded-pill">
                              <v-icon color="white" small>
                                mdi-truck-fast
                              </v-icon>
                            </span>
                          </div>
                        </v-card>
                        <v-card v-else-if="!parseInt(track.data.detail.pickup) || (parseInt(track.data.detail.pickup) && parseInt(track.data.detail.pickup_handling) && track.data.detail.pickup_end)" flat class="d-flex flex-column justify-center fill-width rounded-lg px-0 p-relative" style="min-height: 82px;">
                          {{ parseInt(track.data.detail.pickup) ? $localDT(track.data.detail.pickup_end, 'daydatetime') : $localDT(track.data.detail.created, 'daydatetime') }}
                          <div class="text-uppercase">
                            <div v-if="track.data.detail.notes && ((track.data.detail.notes || 'X').split('[CANCELED]').length >= 2)" class="d-flex align-center red--text caption text-uppercase">
                              <v-icon class="mr-2" small color="red">
                                mdi-arrow-u-left-bottom
                              </v-icon>
                              {{ track.data.detail.notes || '-' }}
                            </div>
                            <span>
                              REGISTERED AT {{ track.data.detail.shipping_origin_city_name }} {{ track.data.detail.shipping_origin_city_type }}
                            </span>
                          </div>
                          <span v-if="track.data.data.length" style="display:block;position: absolute;top:-24%;left:-30px;height:50%;width:0px;border-left:dashed 2px #4CAF50 ;z-index:1;" />
                          <span :class="[track.data.data.length ? 'green' : 'primary']" style="position: absolute;top:calc(50% - 15px);left:-43px;width: 30px;height: 30px;z-index:2;" class="d-flex align-center justify-center green rounded-pill">
                            <v-icon color="white" small>
                              mdi-package-variant-closed
                            </v-icon>
                          </span>
                        </v-card>
                      </div>
                    </v-card-text>
                  </v-card>
                </div>
              </div>
            </div>
          </v-col>
        </v-row>
      </div>
    </section>

    <!-- Features Section -->
    <section class="py-20 bg-gray-50">
        <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <h2 class="text-3xl font-bold text-center mb-12">Why Choose Us</h2>
            <div class="grid grid-cols-1 md:grid-cols-3 gap-8">
                <div class="bg-white p-8 rounded-lg shadow-md text-center">
                    <div class="w-16 h-16 bg-primary/10 rounded-full flex items-center justify-center mx-auto mb-6">
                        <i class="fas fa-truck text-2xl text-primary"></i>
                    </div>
                    <h3 class="text-xl font-semibold mb-4">Fast Delivery</h3>
                    <p class="text-gray-600">Same-day delivery available for local packages and express shipping nationwide.</p>
                </div>
                <div class="bg-white p-8 rounded-lg shadow-md text-center">
                    <div class="w-16 h-16 bg-primary/10 rounded-full flex items-center justify-center mx-auto mb-6">
                        <i class="fas fa-map-marker-alt text-2xl text-primary"></i>
                    </div>
                    <h3 class="text-xl font-semibold mb-4">Invoice & Tracking</h3>
                    <p class="text-gray-600">Track your packages in real-time with our Invoice generated system.</p>
                </div>
                <div class="bg-white p-8 rounded-lg shadow-md text-center">
                    <div class="w-16 h-16 bg-primary/10 rounded-full flex items-center justify-center mx-auto mb-6">
                        <i class="fas fa-shield-alt text-2xl text-primary"></i>
                    </div>
                    <h3 class="text-xl font-semibold mb-4">Secure Handling</h3>
                    <p class="text-gray-600">Your packages are handled with care and fully insured during transit.</p>
                </div>
            </div>
        </div>
    </section>

    <!-- CTA Section -->
    <section id="loginto" class="py-20 bg-black text-white">
        <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
            <h2 class="text-3xl font-bold mb-6">Ready to Ship?</h2>
            <p class="text-lg mb-8 max-w-2xl mx-auto">Join thousands of satisfied customers who trust us with their deliveries.</p>
            <div class="d-flex justify-center align-center pb-16">
              <div class="text-center px-4" style="min-width: 300px;">
                <v-row no-gutters class="justify-center align-center">
                  <v-col cols="12" class="col-sm-8 px-4 d-flex justify-center align-center text-center text-h4 pt-0 pb-8">
                    <v-img
                      src="/img.jpg"
                      size="145"
                      max-width="145"
                    />
                  </v-col>
                  <!-- <v-col cols="12" class="col-sm-8 mt-2 pa-4 justify-center text-center text-h5 mb-4">
                    ABC LOGISTIK
                  </v-col> -->
                </v-row>
                <span
                  class="orange--text font-weight-bold white--text text-h6"
                >
                  - {{ $store.state.isClient ? 'BUSINESS' : 'ADMIN' }} DASHBOARD -
                </span>
                <v-divider class="my-8" />
                <v-text-field
                  v-model="login.username"
                  label="Username"
                  outlined
                  dark
                  hide-details
                  class="mb-4"
                  @keypress.enter="AUTH_LOGIN"
                />
                <v-text-field
                  v-model="login.password"
                  label="Password"
                  type="password"
                  outlined
                  dark
                  hide-details
                  class="mb-8"
                  @keypress.enter="AUTH_LOGIN"
                />
                <v-divider class="mb-4" dark />
                <button class="btn-primary white--text px-6 py-2 rounded-full c-pointer" @click.prevent="AUTH_LOGIN">
                  <v-icon class="mr-2" color="white" small>
                    mdi-account-lock
                  </v-icon>
                  Login
                </button>
                <div class="pt-8">
                  Need an access? contact our team via whatsapp <a href="https://api.whatsapp.com/send?phone=6285213311314&text=Hello%20XTI" target="_blank" class="font-weight-bold">+62 852 1331 1314</a>
                </div>
              </div>
            </div>
        </div>
    </section>

    <!-- Footer -->
    <footer class="bg-white py-12">
        <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div class="grid grid-cols-1 md:grid-cols-4 gap-8">
                <div>
                    <span class="text-2xl font-bold text-primary">Xie<span class="text-black">Trans</span></span>
                    <p class="mt-4 text-gray-600">Professional & smart delivery services you can trust.</p>
                </div>
                <div>
                    <h4 class="text-lg font-semibold mb-4">Quick Links</h4>
                    <ul class="space-y-2 text-gray-600">
                        <li><a href="#" class="hover:text-primary">About Us</a></li>
                        <li><a href="#" class="hover:text-primary">Services</a></li>
                        <li><a href="#" class="hover:text-primary">Tracking</a></li>
                        <li><a href="#" class="hover:text-primary">Contact</a></li>
                    </ul>
                </div>
                <div>
                    <h4 class="text-lg font-semibold mb-4">Services</h4>
                    <ul class="space-y-2 text-gray-600">
                        <li><a href="#" class="hover:text-primary">Express Delivery</a></li>
                        <li><a href="#" class="hover:text-primary">International Shipping</a></li>
                        <li><a href="#" class="hover:text-primary">Bulk Shipping</a></li>
                        <li><a href="#" class="hover:text-primary">Storage Solutions</a></li>
                    </ul>
                </div>
                <div>
                    <h4 class="text-lg font-semibold mb-4">Contact Us</h4>
                    <ul class="space-y-2 text-gray-600">
                        <li><a href="https://api.whatsapp.com/send?phone=6285213311314&text=Hello%20XTI" target="_blank"><i class="fas fa-phone mr-2 text-primary"></i> +62 852 1331 1314</a></li>
                        <li><a href="mailto:info@xti.co.id" target="_blank"><i class="fas fa-envelope mr-2 text-primary"></i> info@xti.co.id</a></li>
                        <li><i class="fas fa-map-marker-alt mr-2 text-primary"></i> 123 Delivery Street</li>
                    </ul>
                </div>
            </div>
            <div class="border-t mt-12 pt-8 text-center text-gray-600">
                <p>&copy; 2024 XieTrans. All rights reserved.</p>
            </div>
        </div>
    </footer>
  </div>
</template>

<script>
let timeOutOrigin = null
let timeOutDestination = null
let ttkTimeout = null
export default {
  data: () => ({
    track: {
      ttk: null,
      loading: false,
      data: null
    },
    OngkirData: [],
    login: {
      username: '',
      password: '',
      business: false
    },
    origin: 0,
    destination: 0,
    shippingOriginQuery: '',
    shippingOriginData: [],
    shippingDestinationQuery: '',
    shippingDestinationData: [],
    loading_pricing: false
  }),
  watch: {
    shippingOriginQuery (v) {
      if (!v) {
        return false
      }
      if (timeOutOrigin) {
        clearTimeout(timeOutOrigin)
      }
      timeOutOrigin = setTimeout(() => {
        this.LoadPricing()
        this.AREA_REQUEST(true)
      }, 300)
    },
    shippingDestinationQuery (v) {
      if (!v) {
        return false
      }
      if (timeOutDestination) {
        clearTimeout(timeOutDestination)
      }
      timeOutDestination = setTimeout(() => {
        this.LoadPricing()
        this.AREA_REQUEST()
      }, 300)
    },
    'track.ttk' (v) {
      if (ttkTimeout) {
        clearTimeout(ttkTimeout)
      }
      ttkTimeout = setTimeout(() => {
        if (v && v.length > 5) {
          this.TRACK_TTK()
        } else {
          this.track.data = null
        }
      }, 300)
    }
  },
  methods: {
    async TRACK_TTK () {
      this.track.loading = true
      this.track.data = null
      const x = await this.$store.dispatch('logistic/PICKUP_SCAN_AWB', this.track.ttk)
        .then(res => res)
      if (x && x.status && x.data?.data?.detail) {
        const xx = {
          data: x.data.data.data || [],
          detail: x.data.data.detail || null
        }
        this.track.data = xx
      }
      this.track.loading = false
    },
    AREA_REQUEST (isOrigin, svpOrigin) {
      let q = '?q='
      if (isOrigin) {
        q += (svpOrigin || this.shippingOriginQuery)
        if (svpOrigin) {
          this.shippingOriginQuery = svpOrigin
        }
      } else {
        q += this.shippingDestinationQuery
      }
      this.$store.dispatch('KECAMATAN_GET', q).then((res) => {
        if (res.status) {
          if (isOrigin) {
            this.shippingOriginData = res.data.data || []
          } else {
            this.shippingDestinationData = res.data.data || []
          }
        }
      })
    },
    LoadPricing () {
      if (parseInt(this.origin) && parseInt(this.destination)) {
        this.loading_pricing = true
        this.$store.dispatch('logistic/SHIPPING_PRICING_GET', {
          vendor: 0,
          q: '?kecamatan_origin=' + (parseInt(this.origin) || 0) + '&kecamatan_destination=' + (parseInt(this.destination) || 0)
        })
          .then((res) => {
            if (res && res.status) {
              this.OngkirData = res.data.data || []
            }
            this.loading_pricing = false
          })
      }
    },
    AUTH_LOGIN () {
      this.login.business = this.$store.state.isClient
      this.$store.dispatch('AUTH_LOGIN', this.login)
        .then((res) => {
          if (res) {
            if (res.status) {
              if (res.data.data.response && res.data.data.access_token) {
                this.$store.dispatch('TOAST', { show: true, message: 'Logged In' })
                localStorage.setItem('t', res.data.data.access_token)
                this.$store.dispatch('ME')
              } else {
                this.$store.dispatch('TOAST', { show: true, message: res.data.data.message })
              }
            } else {
              this.$store.dispatch('TOAST', { show: true, message: 'Ops, something went wrong' })
            }
          } else {
            this.$store.dispatch('TOAST', { show: true, message: 'Ops, something went wrong' })
          }
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.auth-page {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  padding: 40px 0px!important;
  overflow-y: auto;
  z-index: 1000000000;
  background: #fafafa;
  scroll-behavior: smooth
}
.bg-primary { background-color: #FF6B00; }
.text-primary { color: #FF6B00; }
.border-primary { border-color: #FF6B00; }
.btn-primary {
    background-color: #FF6B00;
    transition: all 0.3s ease;
}
.btn-primary:hover {
    background-color: #E65A00;
}
.xti-awb {
  font-weight: bold;
  &::placeholder {
    color: rgba(255,255,255,.75);
    font-weight: 400;
  }
}
</style>
